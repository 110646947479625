





















import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import MediaTracker from '@/components/media/Tracker.vue';

export default Vue.extend({
  components: {
    MediaTracker,
  },
  data() {
    return {
      error: null as null | Error,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      find: 'business/commission/Find',
      list: 'business/commission/trackers/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    business(): ApiResource.Business {
      return this.detect(this.VANITY);
    },
    commission(): ApiResource.Commission {
      return this.find(this.business.id, this.COMMISSION_ID);
    },
    trackers(): ApiResource.ShippingTracker[] {
      return this.list(this.COMMISSION_ID);
    },
  },
  watch: {
    '$route.params.COMMISSION_ID': {
      immediate: true,
      async handler(to) {
        this.$store.dispatch('business/commission/trackers/List', {
          COMMISSION_ID: to,
          BUSINESS_ID: this.business.id,
        });
      },
    },
  },
});
